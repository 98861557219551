import styled from "styled-components";
import { Paper } from "@material-ui/core";

export const PaperTitle = styled.div`
  font-weight: bold;
  margin: 10px 0;
`;
export const StyledPaper = styled(Paper)`
  margin: 20px 0;
  padding: 10px 20px;
`;
export const PaperContent = styled.div`
  margin: 10px 0 20px;
`;
export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;
export const FieldLabel = styled.div<{$width?: string}>`
  flex: 0 0 ${({$width}) => $width ? $width : "200px"};
`;
export const FieldItem = styled.div<{$width?: string}>`
  flex: 0 0 ${({$width}) => $width ? $width : "250px"};
`;
export const SaveButtonArea = styled.div`
  margin: 20px 0;
`;
