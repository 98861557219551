import React, { FC, HTMLAttributes } from "react";
import styled from "styled-components";

type Props = HTMLAttributes<HTMLDivElement> & { organizationName: string };
const OrganizerArea: FC<Props> = ({ organizationName, ...props }) => {
  return (
    <BadgeArea {...props}>
      <Badge>
        <span>{organizationName}</span>
      </Badge>
    </BadgeArea>
  );
};
export default OrganizerArea;

const BadgeArea = styled.div`
`;
const Badge = styled.div`
  background-position: left;
  background-size: 15px;
  background-image: url("/img/icons/pin.png");
  background-repeat: no-repeat;
  padding: 5px 5px 5px 16px;
`;
