import { useMemo } from "react";

export const useProjectAwardRank = (summary?: { support_amount: number | null }): "award--gold" | "award--silver" | null => {
  return useMemo(() => {
    if (summary === undefined || summary.support_amount === null) {
      return null;
    } else if (summary.support_amount > 100_000_000) {
      return "award--gold";
    } else if (summary.support_amount > 75_000_000) {
      return "award--silver";
    } else {
      return null;
    }
  }, [summary?.support_amount]);
};
