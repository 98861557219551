import React, { FC, HTMLAttributes, memo, useMemo } from "react";
import styled from "styled-components";

const ProjectCardRateMeter: FC<HTMLAttributes<HTMLDivElement> & { rate?: number | null }> = ({ rate, ...props }) => {
  const [isSuccess, percent] = useMemo(() => {
    if (rate) {
      const percent = rate >= 100 ? 100 : rate;
      const isSuccess = percent === 100;
      return [isSuccess, percent];
    } else {
      return [false, 0];
    }
  }, [rate]);
  return rate !== null ? (
    <Root {...props}>
      <Fill $percent={percent} />
      {isSuccess && (
        <SuccessArea>
          <SuccessDress1 />
          <SuccessText>SUCCESS!!</SuccessText>
          <SuccessDress2 />
        </SuccessArea>
      )}
    </Root>
  ) : (
    <></>
  );
};
export default memo(ProjectCardRateMeter);

const Root = styled.div`
  position: relative;
  transform: skewX(-10deg);
  background-color: #1a1a1a;
  width: 100%;
  @media screen and (max-width: 960px) {
    height: 13px;
  }
  @media screen and (min-width: 961px) {
    height: 18px;
  }
`;
const Fill = styled.div<{ $percent: number }>`
  background-image: url(/img/bg_overlay.png);
  background-color: #ff0000;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-blend-mode: color-burn;
  width: ${({ $percent }) => $percent}%;
`;
const SuccessArea = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 30%;
    height: 72%;
    position: absolute;
  }

  &:before {
    border-left: 1px solid #ff0000;
    border-bottom: 1px solid #ff0000;
    left: -3px;
    bottom: -3px;
  }

  &:after {
    border-right: 1px solid #ff0000;
    border-top: 1px solid #ff0000;
    right: -3px;
    top: -3px;
  }
`;
const SuccessDress1 = styled.div`
  width: 17.5%;
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  right: 2px;
  border-right: 3px solid #fff;
  border-bottom: 1px solid #fff;
`;
const SuccessDress2 = styled.div`
  width: 17.5%;
  height: calc(100% - 4px);
  position: absolute;
  top: 2px;
  left: 2px;
  border-left: 3px solid #fff;
  border-top: 1px solid #fff; ;
`;
const SuccessText = styled.p`
  font-size: 10px;
  color: #fff;
`;
