import styled from "styled-components";

const ProjectCardBg = styled.div<{ $disabled?: boolean }>`
  position: absolute;
  z-index: -1;
  width: 100%;
  background-color: ${({ $disabled }) => ($disabled ? "#DDD" : "#FFF")};
  top: 0;
  left: 0;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
  }

  &:before {
    left: 0;
    background-color: ${({ $disabled }) => ($disabled ? "#DDD" : "#FFF")};
  }

  &:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-color: ${({ $disabled }) => ($disabled ? "#DDD" : "#FFF")} transparent transparent transparent;
    right: 0;
  }

  @media screen and (max-width: 960px) {
    height: calc(100% - 10px);
    
    &:before {
      height: 10px;
      bottom: -10px;
      // +1pxはデバイス幅が奇数の場合に1pxの隙間が空いてしまう問題対処
      width: calc(100% - 10px + 1px);
    }

    &:after {
      bottom: -10px;
      border-width: 10px 10px 0 0;
    }
  }

  @media screen and (min-width: 961px) {
    height: calc(100% - 20px);

    &:before {
      height: 20px;
      bottom: -20px;
      width: calc(100% - 20px);
    }

    &:after {
      bottom: -20px;
      border-width: 20px 20px 0 0;
    }
  }
`;
export default ProjectCardBg;
