import ProjectCardBg from "components/front/top/layout/ProjectCardBg";
import ProjectCardRateMeter from "components/front/top/ProjectCardRateMeter";
import { ProjectCardRateText } from "components/front/top/UnitTexts";
import { useAmount2 } from "lib/hooks/useAmount2";
import { useProjectAwardRank } from "lib/hooks/useProjectAwardRank";
import { useProjectNewFlag } from "lib/hooks/useProjectNewFlag";
import { useRating } from "lib/hooks/useRating";
import { useRemainingDays } from "lib/hooks/useRemainingDays";
import { Summary } from "lib/service/client/FrontTopPageService";
import dynamic from "next/dynamic";
import Link from "next/link";
import React, { FC } from "react";
import { Props as RemainingTextProps } from "./RemainingText";
import styled from "styled-components";
import useShowsSupportAmount from "../../../lib/hooks/useShowsSupportAmount";
import OrganizerArea from "components/front/top/OrganizerArea";
import { TopPageProject } from "interfaces/front/page/top";
import useServiceType from "lib/hooks/front/useServiceType";

const RemainingText = dynamic<RemainingTextProps>(() => import("./RemainingText"), { ssr: false });

const ProjectCard: FC<{ project: TopPageProject; summary?: Summary; isShopping?: boolean }> = ({ project, summary, isShopping }) => {
  const isNew = useProjectNewFlag(project);
  const awardRank = useProjectAwardRank(summary);
  const remaining = useRemainingDays(project.support_end_at);
  const rate = useRating(project.goal_amount, summary?.support_amount);
  const { isAboutHometownTax } = useServiceType(project.service_type);
  const supportAmountFormat = useAmount2(summary?.support_amount ?? undefined);
  const imageUrl = project.content.main_content_images[0]?.url;
  const showsSupportAmount = useShowsSupportAmount(summary?.support_amount, project.support_amount);
  return (
    <Root className={awardRank ? awardRank : ""}>
      <Link href={`/project/${project.code}`}>
        <LinkArea>
          <ImageArea>
            {isNew && (
              <NewLabel isHometownTax={isAboutHometownTax}>
                <NewLabelText>NEW</NewLabelText>
              </NewLabel>
            )}
            <img src={imageUrl} alt="" />
          </ImageArea>
          <TextArea $isHometownTax={isAboutHometownTax}>
            <Title moreText={!showsSupportAmount}>{project.content.title}</Title>
            <QuantitativeArea>
              {rate !== null && (
                <RateArea>
                  <ProjectCardRateMeter rate={rate} />
                  <RateText>
                    <ProjectCardRateText text={rate?.toString()} unit="%" />
                  </RateText>
                </RateArea>
              )}
              <AmountRemainingArea $visible={showsSupportAmount}>
                  <p>
                    {!isShopping && (
                      <>
                        <SupportAmountText>{supportAmountFormat ?? "計算中"}</SupportAmountText>
                        <SupportAmountUnit shows={!!supportAmountFormat}>円</SupportAmountUnit>
                      </>
                    )}
                  </p>
                <p>
                  {project.code != "kamiari" && <RemainingText remaining={remaining} size={6} />}
                </p>
              </AmountRemainingArea>
              {isAboutHometownTax && <StyledOrganizerArea organizationName={project.organization_name} />}
            </QuantitativeArea>
            <ProjectCardBg />
          </TextArea>
        </LinkArea>
      </Link>
    </Root>
  );
};
export default ProjectCard;

const Root = styled.div`
  margin-bottom: 20px;

  @media screen and (max-width: 960px) {
    width: calc((100% - 10px) / 2);
    &:nth-child(n + 3) {
      margin-top: 10px;
    }
  }
  @media screen and (min-width: 961px) {
    width: calc((100% - 30px) / 3);
    &:nth-child(n + 4) {
      margin-top: 15px;
    }
  }
  @media (min-width: 1921px) {
    width: calc((100% - 32px) / 3);
    &:nth-child(n + 4) {
      margin-top: 15px;
    }
  }

  &.award--gold .new_label,
  &.award--silver .new_label {
    display: none;
  }
`;
const LinkArea = styled.a`
  transition: 0.4s;
  display: block;
  position: relative;
  @media screen and (min-width: 961px) {
    &:hover {
      filter: drop-shadow(3px 3px 0 lime);
    }
  }
`;
const ImageArea = styled.div`
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  @media screen and (max-width: 960px) {
    height: auto;
  }
  @media screen and (min-width: 961px) {
    height: 174px;
  }
  @media screen and (min-width: 1281px) {
    height: 196px;
  }
  @media screen and (min-width: 1921px) {
    height: 234px;
  }

  & > img {
    width: 100%;
    height: auto;
  }
`;
const NewLabel = styled.div<{ isHometownTax: boolean }>`
  background-image: url(${({ isHometownTax }) => (isHometownTax ? "/img/new_bg_green.png" : "/img/new_bg.png")});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  @media screen and (max-width: 960px) {
    width: 46px;
    height: 46px;
  }
  @media screen and (min-width: 961px) {
    width: 72px;
    height: 72px;
  }
`;
const NewLabelText = styled.p`
  color: #fff;
  padding-top: 14px;
  padding-left: 2px;
  font-style: italic;
  @media screen and (max-width: 960px) {
    font-size: 10px;
    padding-top: 8px;
  }
`;
const TextArea = styled.div<{ $isHometownTax: boolean }>`
  position: relative;
  z-index: 2;
  @media screen and (max-width: 960px) {
    padding: 10px;
    height: ${({ $isHometownTax }) => ($isHometownTax ? "158px" : "132px")};
  }
  @media screen and (min-width: 961px) {
    padding: 20px;
    height: ${({ $isHometownTax }) => ($isHometownTax ? "220px" : "194px")};
  }
  @media screen and (min-width: 1281px) {
    height: ${({ $isHometownTax }) => ($isHometownTax ? "233px" : "203px")};
  }
`;
const RateArea = styled.div`
  margin-bottom: 10px;
  color: #1a1a1a;
  display: flex;
  align-items: flex-end;
  width: 100%;
`;
const RateText = styled.span`
  padding-left: 4px;
  @media screen and (max-width: 960px) {
    font-size: 13px;
  }
  @media screen and (min-width: 961px) {
    font-size: 16px;
  }
  @media screen and (min-width: 1281px) {
    padding-left: 8px;
  }
`;
const QuantitativeArea = styled.div`
  position: absolute;
  @media screen and (max-width: 960px) {
    width: calc(100% - 20px);
    bottom: 20px;
  }
  @media screen and (min-width: 961px) {
    width: calc(100% - 40px);
    bottom: 40px;
  }
`;
const Title = styled.p<{ moreText: boolean }>`
  width: 100%;
  min-height: 4.5em;
  display: -webkit-box;
  -webkit-line-clamp: ${({ moreText }) => (moreText ? "5" : "3")};
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
  @media screen and (max-width: 960px) {
    font-size: 12px;
    margin-bottom: 9px;
  }
  @media screen and (min-width: 961px) {
    font-size: 16px;
    margin-bottom: 18px;
  }
  @media screen and (min-width: 1281px) {
    font-size: 18px;
    margin-bottom: 18px;
  }
`;
const SupportAmountText = styled.span`
  letter-spacing: 0.1em;
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (min-width: 961px) and (max-width: 1280px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1281px) {
    font-size: 16px;
  }
`;
const SupportAmountUnit = styled.span<{ shows: boolean }>`
  display: ${({ shows }) => (shows ? "inline" : "none")};
  margin-left: 4px;
  @media screen and (max-width: 960px) {
    font-size: 10px;
  }
  @media screen and (min-width: 961px) and (max-width: 1280px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1281px) {
    font-size: 14px;
  }
`;
const AmountRemainingArea = styled.div<{ $visible: boolean }>`
  display: flex;
  justify-content: space-between;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
`;
const StyledOrganizerArea = styled(OrganizerArea)`
  margin-top: 5px;
  @media screen and (max-width: 960px) {
    font-size: 11px;
  }
  @media screen and (min-width: 961px) and (max-width: 1280px) {
    font-size: 13px;
  }
  @media screen and (min-width: 1281px) {
    font-size: 15px;
  }
`;
