import { styled } from "@material-ui/core/styles";
import React, { FC, HTMLAttributes } from "react";
import { ssPriceText } from "../layout/SsStyles";

export const SsUnitText: FC<
  HTMLAttributes<HTMLSpanElement> & {
    text?: string;
    unit?: string;
  }
> = ({ text, unit, ...props }) => {
  return (
    <span {...props}>
      {text && <span>{text}</span>}
      {unit && <span>{unit}</span>}
    </span>
  );
};

export const ProjectCardRateText = styled(SsUnitText)(({ theme }) => ({
  "& > span:first-child": {
    ...ssPriceText,
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "17px",
    },
  },
  "& > span:last-child": {
    fontStyle: "italic",
    marginLeft: "2px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "10px",
    },
  },
}));
