import { differenceInCalendarDays } from "date-fns";
import { useMemo } from "react";
import { Project } from "../../interfaces/front/business";

export const useProjectNewFlag = (project: Project): boolean => {
  return useMemo(() => {
    const gapDay = differenceInCalendarDays(new Date(), new Date(project.support_start_at));
    return gapDay < 14;
  }, [project.support_start_at])
};
